<template>

        <iframe :src="paymentUrl" title="Payment" style="width:100%;height: 100%;"></iframe>

</template>

<script>
export default {
  components: { },
  data() {
    return{
        paymentUrl:""
    }
  },
  async beforeCreate() {
    console.log('dahfdgahfhgadfgdfgahf',localStorage.getItem('payment_url'));
        if(localStorage.getItem('payment_url')){
            this.paymentUrl = await localStorage.getItem('payment_url');
        }
  },
  async mounted(){
    console.log('dahfdgahfhgadfgdfgahf',localStorage.getItem('payment_url'));
        if(localStorage.getItem('payment_url')){
            this.paymentUrl = await localStorage.getItem('payment_url');
        }

  }

};
</script>
